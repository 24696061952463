<template>
  <div class="contact">
    <div class="center-wrapper contact-wrapper">
    <div class="contact-details">
      <h1>Wishlist</h1>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  metaInfo() {
    return {
      title: 'Wishlist'
    }
  }
}
</script>

<style lang="sass" scoped>
.contact-wrapper
  display: flex
  .contact-details
    display: flex
    flex-direction: column
    width: 40%
    margin: 2.5em 0 9.4em 0 
    @media screen and (max-width: $lg-breakpoint)
      width: 100%
</style>
